
import { defineComponent } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFooter,
  IonInput,
  IonProgressBar,
  IonIcon,
} from '@ionic/vue';
import DiscoveryCard from '@/components/cards/DiscoveryCard.vue';
import store from '@/store';
import axios from 'axios';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'Entdecken',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFooter,
    IonInput,
    IonProgressBar,
    IonIcon,
    DiscoveryCard,
  },
  data() {
    return {
      infiniteScrollFinished: false,
      discoveryEntries: [],
      discoveryEntriesSorted: [] as Array<any>,
      discoverySearchTerm: '',
      numberOfDisplayedDiscoveries: 20,
      loading: {
        discoveries: true,
      },
    };
  },
  mounted() {
    this.getDiscoveryData();
  },
  watch: {
    discoverySearchTerm() {
      this.filterDiscoveryEntries(this.discoveryEntries);
    },
  },
  computed: {
    loggedIn() {
      return store.getters.getLoginState;
    },
  },
  methods: {
    getDiscoveryData(quantity = '1000', offset = '0') {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'bigSearch',
          limit: `${offset},${quantity}`,
          orderby: 'creation_date',
          searchTable: 'discoveries',
          searchColumn: 'title',
          sort: 'DESC',
          term: '',
        })
        .then(response => {
          this.discoveryEntries = response.data;
          this.loading.discoveries = false;
          this.filterDiscoveryEntries(this.discoveryEntries);
        })
        .catch(error => {
          console.error(error);
        });
    },
    loadMoreDiscoveries(quantity = 20, event: any) {
      this.numberOfDisplayedDiscoveries += quantity;

      if (this.numberOfDisplayedDiscoveries > this.discoveryEntries.length) {
        this.numberOfDisplayedDiscoveries = this.discoveryEntries.length;
        this.infiniteScrollFinished = true; // no more infinite loading happening
      } else {
        const infiniteScroll: any = document.getElementById('infinite-scroll');
        if (infiniteScroll) {
          event.target.complete(); // infinite scroll finished, ready for new triggering
        }
      }
    },
    filterDiscoveryEntries(initialDiscoveryArray: Array<any>) {
      if (this.discoverySearchTerm === '') {
        this.discoveryEntriesSorted = this.discoveryEntries;
      } else {
        this.discoveryEntriesSorted = initialDiscoveryArray.filter(discovery => {
          const term = this.discoverySearchTerm.toLowerCase();
          if (discovery.title.toLowerCase().includes(term) || discovery.content.toLowerCase().includes(term)) {
            return true;
          }
        });
      }
    },
  },
});
