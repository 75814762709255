
import { defineComponent } from 'vue';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton } from '@ionic/vue';
import { openInAppBrowser } from '@/utils/methods/capacitorMethods';
import { tblVars } from '@/variables/environmentVariables';

interface SingleDiscovery {
  id: string;
  title: string;
  slug: string;
  main_image: string | null;
  content: string;
  author: string;
  external_url: string | null;
  creation_date: string;
  active: string;
}

export default defineComponent({
  name: 'DiscoveryCard',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
  },
  props: {
    discoveryObject: {
      type: Object as () => SingleDiscovery,
    },
  },
  data() {
    return {
      tblVars,
    };
  },
  methods: {
    openInAppBrowser,
  },
});
